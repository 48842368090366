import {
    createStore
} from 'vuex'
import {
    dayjs
} from 'element-plus'
export default createStore({
    state: {
        today: '',
        lastweek: '',
        lastmonth: '',
        month: '',
        uri: '/Login',
        innerHeight: 0,
        screenWidth: 0,
        route: [],
        uname: '',
        akey: ''
    },
    getters: {
        today: state => state.today,
        lastweek: state => state.lastweek,
        lastmonth: state => state.lastmonth,
        uri: state => state.uri,
        innerHeight: state => state.innerHeight,
        screenWidth: state => state.screenWidth,
        route: state => state.route,
        uname: state => state.uname,
        akey: state => state.akey
    },
    mutations: {
        SETURI(state, data) {
            state.uri = data
        },
        SETUNAME(state, data) {
            state.uname = data
        },
        SETAKEY(state, data) {
            state.akey = data
        },
        SETIH(state, data) {
            state.innerHeight = data
        },
        SETSW(state, data) {
            state.screenWidth = data
        },
        SETROUTE(state, data) {
            state.route = data
        },
        SETODAY(state, data) {
            let date = new Date()
            state.today = dayjs(date.getTime()).format('YYYY-MM-DD')
            state.month = dayjs(date.getTime()).format('YYYY-MM')
            state.lastweek = dayjs(date.getTime() - 3600 * 1000 * 24 * 7).format('YYYY-MM-DD')
            state.lastmonth = dayjs(date.getTime() - 3600 * 1000 * 24 * 30).format('YYYY-MM-DD')
        },
    },
    actions: {
        SETIH({
            commit
        }, data) {
            commit('SETIH', data)
        },
        SETAKEY({
            commit
        }, data) {
            commit('SETAKEY', data)
        },
        SETUNAME({
            commit
        }, data) {
            commit('SETUNAME', data)
        },
        SETROUTE({
            commit
        }, data) {
            commit('SETROUTE', data)
        },
        SETSW({
            commit
        }, data) {
            commit('SETSW', data)
        },
        SETURI({
            commit
        }, data) {
            commit('SETURI', data)
        },
        SETODAY({
            commit
        }, data) {
            commit('SETODAY', data)
        }
    },
    modules: {}
})