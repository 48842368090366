import axios from 'axios'
import * as dd from 'dingtalk-jsapi';
import router from '@/router'
import {
    ElMessage,
} from 'element-plus'
//注册axios，设置请求根路径
axios.defaults.baseURL = '/public/index.php'
//请求拦截器 挂在令牌到请求头
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.localStorage.getItem('token')
    const jurisdiction = JSON.parse(window.localStorage.getItem('jurisdiction'))
    if (jurisdiction) {
        config.data.jurisdiction = jurisdiction //用户权限
    }
    return config
})
//响应拦截器
axios.interceptors.response.use(response => {
    if (response.status != 200) {
        ElMessage({
            type: "warning",
            message: '请求出错',
        })
    } else {
        if (response.data.code == 200) {
            return response.data.data
        } else if (response.data.code == 401) {
            if (dd.env.platform != 'notInDingTalk') {
                // 令牌过期
                const jurisdiction = JSON.parse(window.localStorage.getItem('jurisdiction'))
                // 登陆续签
                axios({
                    method: "post",
                    url: 'dlogin',
                    data: {
                        corpId: '',
                        uid: jurisdiction.uid,
                        upup: false,
                    },
                }).then(res => {
                    window.localStorage.setItem('token', res.token)
                    window.localStorage.setItem('jurisdiction', JSON.stringify(res.jurisdiction))
                    window.localStorage.setItem('route', JSON.stringify(res.route));
                    router.push(window.location.hash.replace('#', ""))
                    ElMessage({
                        type: "warning",
                        message: '请求失败,请刷新重试',
                    })
                })
            } else {
                router.push('/')
                window.sessionStorage.clear()
                window.localStorage.clear()
                window.location.reload()
                ElMessage({
                    type: "warning",
                    message: '令牌过期',
                })
            }
        } else {
            ElMessage({
                type: "warning",
                message: response.data.mag,
            })
            return response.data.data
        }
    }
}, err => {
    return Promise.reject(err)
})
//导出请求
export default axios