import {
    createApp
} from 'vue'
import App from './App.vue'
// 引用路由
import router from './router'
//引入vuex
import store from './store'
import 'element-plus/dist/index.css'
import './assets/css/common.css'
import 'element-plus/theme-chalk/display.css'
// import '@/utils/ribbon.js'
//解决谷歌浏览器划动报错
import 'default-passive-events'
//引入全部组件
import ElementPlus from 'element-plus'
//按需引入图标
import {
    Setting,
    SwitchButton,
    Edit,
    View,
    Hide,
    UploadFilled,
    Eleme,
    Search,
    ChatDotRound,
    Connection,
    MessageBox,
    Tickets,
    Postcard,
    Document,
    Files,
    Star,
    Expand,
    Opportunity,
    Loading,
    Paperclip,
    CirclePlusFilled,
    MagicStick,
    Clock,
    CircleCheck,
    CircleClose,
    CircleCloseFilled,
    Refresh,
    Message
} from '@element-plus/icons-vue'

// 如果您正在使用CDN引入，请删除下面一行。
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//引入组件语言
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const app = createApp(App).use(store).use(router)
//按需注册图标
app.component('Message', Message)
app.component('ChatDotRound', ChatDotRound)
app.component('Refresh', Refresh)
app.component('SwitchButton', SwitchButton)
app.component('CircleCloseFilled', CircleCloseFilled)
app.component('Setting', Setting)
app.component('Files', Files)
app.component('View', View)
app.component('Hide', Hide)
app.component('Document', Document)
app.component('Postcard', Postcard)
app.component('Tickets', Tickets)
app.component('MessageBox', MessageBox)
app.component('Connection', Connection)
app.component('Edit', Edit)
app.component('Expand', Expand)
app.component('Eleme', Eleme)
app.component('Star', Star)
app.component('Search', Search)
app.component('UploadFilled', UploadFilled)
app.component('Loading', Loading)
app.component('Opportunity', Opportunity)
app.component('Paperclip', Paperclip)
app.component('CirclePlusFilled', CirclePlusFilled)
app.component('MagicStick', MagicStick)
app.component('Clock', Clock)
app.component('CircleCheck', CircleCheck)
app.component('CircleClose', CircleClose)
app.use(ElementPlus, {
    size: 'default',
    zIndex: 3000,
    locale: zhCn
}).mount('#app')