import {
    Vue
} from 'vue'
import {
    createRouter,
    VueRouter,
    createWebHashHistory
} from 'vue-router'
import store from '@/store'
// Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

const routes = [{
        path: '/',
        redirect: '/Login'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () =>
            import('../views/404.vue')
    },
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import('../views/Login.vue')
    },
    {
        path: '/Upload',
        name: 'Upload',
        component: () =>
            import('../views/Upload.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//定义变量判断是否已经动态添加过，如果刷新后load永远为 0
let load = 0
//这是一个导航守卫
router.beforeEach(async (to, form, next) => {
    if (to.path === '/Login') return next()
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/Login')
    //路由缓存
    if (load === 0 && to.name !== 'Login') {
        const route = JSON.parse(window.localStorage.getItem('route'))
        const jurisdiction = JSON.parse(window.localStorage.getItem('jurisdiction'))
        load++
        // 初始化全局日期
        store.dispatch('SETODAY')
        // 全局路由
        // 全局用户名
        store.dispatch('SETUNAME', jurisdiction.realname)
        store.dispatch('SETAKEY', jurisdiction.post)
        // 全局路由表
        store.dispatch('SETROUTE', route)
        // 加载路由表
        await addData(route)
        next({
            path: to.path,
            replace: true
        })
    } else {
        next()
    }
    store.dispatch('SETURI', to.path)
})


//动态添加路由方法
export function addData(route) {
    route.forEach(item => {
        router.addRoute({
            path: '/' + item.path,
            name: item.path,
            component: () => import(`../views/${item.path}.vue`),
        })
    })
    return router
}


export default router